@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Cloister;
  src: url(/static/media/CloisterBlack.d15613cd.ttf);
}

@font-face {
  font-family: Coronet;
  src: url(/static/media/Coronet_Bold.82460b0f.ttf);
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.section-title {
  font-weight: bold;
  font-family: "Lato";
  font-size: 4vh;
}

.about {
  font-size: 7vmin !important;
  border-bottom: 2px solid black !important;
}

.verify-table {
  font-family: "Lato";
}

.register-title {
  font-size: 7vmin !important;
}

.register-btn {
  font-family: "Lato";
  font-weight: bold;
}
.register-btn :hover {
  background-color: rgb(255, 177, 32) !important;
}

.about-description {
  font-family: "Lato";
  font-size: 18px !important;
}

.section {
  width: 100vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.jumbotron {
  margin-bottom: 0 !important;
}
@media print {
  #Header,
  footer {
    display: none !important;
  }
  * {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
  .row,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .trans {
    background-color: rgba(255, 255, 255, 0) !important;
  }
}

.about-text {
  font-size: medium;
}

